.callout {
  .callout-box {
    @include make-md-column(6);
    margin-top: 40px;
    margin-left: -$grid-gutter-width;
    padding-left: $grid-gutter-width;
    background-color: $color-white-opaque;
    font-size: $font-size-medium;

    p {
      padding-bottom: 10px;
    }

    h1 {
      font-size: $font-size-large;
      font-weight: bold;
      line-height: 2;
    }

    @media(min-width: $screen-md-min) {
      font-size: 25px;

      p {
        padding-bottom: 0;
      }
    }
  }
}

.carousel.callout {
  .carousel-inner {
    padding: 0;

    .item {
      height: 220px;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $color-white;

      @media(min-width: $screen-md-min) {
        height: 350px;
      }
    }
  }

  .carousel-control {
    background-image: none;
  }
}

.nav {
    position: sticky;
    top: 0;
    z-index: 100;

    .nav-content {
        background-color: $color-white;
    }

    ul.nav-list {
        background: $color-lighter-blue;
        padding: 15px;
        text-transform: uppercase;
        list-style-type: none;

        >li {

            a {
                color: $color-black;
            }

            &.active,
            &.current {
                >a {
                    color: $color-blue;
                }
            }

            ul.nav-sub {
                list-style-type: none;
                margin: 0;
                padding: 0;

                li {
                    font-size: $font-size-medium;
                    line-height: $font-size-large;
                    margin: 2px 0;

                    &.active,
                    &.current {
                        >a {
                            color: $color-blue;
                        }
                    }
                }
            }
        }
    }
}

.nav-header {
    @include clearfix;
    background: $color-lighter-blue;
    margin: 0;

    @media (min-width: $grid-float-breakpoint) {
        float: left;
    }
}

.nav-collapse {
    overflow-x: visible;
    margin-right: -5px;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, .1);
    -webkit-overflow-scrolling: touch;
    @include clearfix;

    &.in {
        overflow-y: auto;
    }

    @media (min-width: $grid-float-breakpoint) {
        width: auto;
        box-shadow: none;

        &.collapse {
            display: block !important;
            visibility: visible !important;
            height: auto !important;
            padding-bottom: 0;
            overflow: visible !important;
        }

        &.collapsing {
            height: 0;
        }

        &.in {
            overflow-y: visible;
        }

        .nav-fixed-top &,
        .nav-static-top &,
        .nav-fixed-bottom & {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.collapse {
    display: none;
    visibility: hidden;

    &.in {
        display: block;
        visibility: visible;
    }
}

.nav-toggle {
    position: relative;
    float: left;
    height: 30px;
    padding: 7px 0;
    margin: 5px 15px;
    background-color: $color-lighter-blue;
    border: 1px solid transparent;

    &:focus {
        outline: 0;
    }

    .icon-bar {
        display: block;
        width: 25px;
        height: 3px;
        border-radius: 1px;
        background-color: $color-blue;
    }

    .icon-bar+.icon-bar {
        margin-top: 4px;
    }

    @media (min-width: $grid-float-breakpoint) {
        display: none;
    }
}

@media(min-width: $screen-lg-min) {
    .nav {
        background-color: $color-lighter-blue;

        .nav-content {
            position: relative;
        }

        ul.nav-list {
            @include make-md-column(12);
            list-style-type: none;
            padding: 0;
            margin: 0;

            >li {
                display: inline-block;
                color: lighten($color-black, 5);
                line-height: 65px;
                font-weight: normal;

                &:first-child {
                    padding-left: 10px;
                }

                &:last-child {
                    padding-right: 10px;
                }

                +li:before {
                    padding: 0 1.5% 0 1%;
                    content: '|';
                    color: $color-blue;
                    font-weight: normal;

                    @media(min-width: $screen-lg-min) {
                        padding: 0 14px 0 11px;
                    }
                }

                +li ul.nav-sub {
                    margin: 0 34px;
                }

                >a {
                    display: inline-block;
                    height: 65px;
                    color: inherit;

                    &:hover {
                        color: $color-blue;
                    }
                }

                ul.nav-sub {
                    display: none;
                }
            }

            >li:hover,
            >li.hover {

                >a {
                    border-bottom: 10px solid $color-white;
                }

                ul.nav-sub {
                    display: block;
                    position: absolute;
                    z-index: 1000;
                    background-color: $color-white;

                    li {
                        line-height: 2;
                        padding: 5px 20px;
                        font-size: 18px;

                        &.active,
                        &.current {
                            a {
                                color: $color-blue;
                            }
                        }

                        a {
                            color: $color-black;

                            &:hover {
                                color: $color-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}

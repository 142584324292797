.header {
    @media (min-width: $screen-md-min) {
        padding: 20px 0 !important;
    }
    background-color: $color-white;

    a.header-site {
        @include make-xs-column(12);
        @include make-sm-column(5);
        @include make-md-column(5);
        display: block;
        width: 100%;
        height: 120px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: auto 73%;

        &.wessendorf {
            background-image: url('../Assets/layout/logo-wessendorf.svg');
        }

        &.fontus {
            background-image: url('../Assets/layout/logo-fontus.svg');
        }

        &.phora {
            background-image: url('../Assets/layout/logo-phora.png');
            background-size: 88px;
            background-position-x: 21px;
        }

        &.margin-sm {
            margin: 0 2%;
        }

        @media (min-width: $screen-md-min) {
            position: relative;
            left: $grid-gutter-width;
            // width: 24%;
            height: 88px;
            margin: 10px 0 0 0;
            background-size: auto;
            background-position: left;

            // Align in IE >= 10
            @at-root {

                :-ms-input-placeholder,
                :root a.header-site.wessendorf {
                    left: 12px;
                }

                :-ms-input-placeholder,
                :root a.header-site.fontus {
                    left: 0;
                }

                :-ms-input-placeholder,
                :root a.header-site.phora {
                    left: -10px;
                }
            }

            &.margin-sm {
                margin: 0 1%;
            }
        }

        &.active,
        &:hover {
            text-decoration: none;

            img.header-logo,
            h1.header-caption {
                opacity: 1;
            }
        }
    }

    .header-contact {
        @include make-xs-column(12);
        @include make-sm-column(4);
        @include make-md-column(3);
        @include make-sm-column-offset(3);
        @include make-md-column-offset(4);
        height: 100px;
        padding-top: 15px;
        color: $color-blue;
        font-weight: normal;
        text-transform: uppercase;
        font-size: $font-size-medium;

        @media (min-width: $screen-sm-min) {
            font-size: $font-size-base;

            .header-tel {
                font-size: $font-size-large;
                font-weight: bold;
            }
        }

        @media (min-width: $screen-md-min) {
            padding: 10px 15px 0;
            text-align: right;
        }
    }

    .header-mobil {
        input {
            @include make-xs-column(12);
            margin-top: 15px;
            background-color: $color-blue;
            border: none;
            color: $color-white;
            text-transform: uppercase;
            border-radius: 0;
        }
    }
}

.breadcrumb {
  position: relative;
  padding: 0;

  .breadcrumb-list {
    @include make-md-column(6);
    position: absolute;
    top: -36px;
    height: 36px;
    background-color: $color-white-opaque;
    padding: 12px $grid-gutter-width 10px;
    margin-left: -($grid-gutter-width/2)+4px;
    margin-bottom: 0;
    border: none;
    font-size: 11px;
    line-height: 14px;
    list-style: none;

    > li {
      display: inline-block;
      text-transform: uppercase;
      color: $color-blue;

      + li:before {
        padding: 0 2px;
        content: "#{">"}\00a0";
        color: $color-blue;
      }
    }
  }

}

.footer {

  nav.footer-nav {
    min-height: 220px;
    background-color: $color-lighter-blue;

    ul.footer-nav-list {
      padding: 20px 0 0 $grid-gutter-width;
      list-style-type: none;

      > li {
        @include make-xs-column(12);
        @include make-md-column(4);
        @include make-lg-column(2);
        display: block;
        float: left;
        padding-left: 0;
        margin: 15px 0;
        font-size: 14px;

        @media(min-width: $screen-lg-min) {
          margin-top: 0;
          margin-right: 3.3%;
        }

        a {
          color: $color-black;
          font-weight: 400;
        }

        > a {
          text-transform: uppercase;
        }

        ul {
          clear: both;
          list-style-type: none;
          padding-left: 0;
          padding-top: 5px;

          > li {
            padding: 1px 0;
          }

          a {
            font-weight: 300;
          }
        }
      }
    }
  }

  .footer-extra {
    min-height: 150px;
    background-color: $color-white;

    .logo {
      @include make-xs-column(12);
      @include make-sm-column(6);
      @include make-md-column(3);
      @include make-lg-column(2);
      padding: 20px $grid-gutter-width;
    }

    .list {
      padding: 20px $grid-gutter-width;
      text-transform: uppercase;
      text-align: left;
      list-style-type: none;

      @media(min-width: $screen-lg-min) {
        text-align: right;
      }

      li {
        display: inline-block;

        + li:before {
          padding: 0 6px;
          content: '|';
          font-size: 18px;
          color: $color-blue;
        }
      }
    }
  }
}

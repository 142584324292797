//
// Typography
//
body {
  font-weight: 300;
}

h1, h2, h3, h4, h5 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 400;
}

h2 {
  letter-spacing: 1.5px;
}

a,
a:hover,
a:focus,
a:active {
  color: $color-blue;
  text-decoration: none;
}

img {
  max-width: 100%;
}

//
// Layout
//
body > .content {
    @extend .container;
    min-height: 600px;
}

.site-nav,
.callout-content {
  padding: 0 $grid-gutter-width;
}

.grid-content:first-of-type {
  padding-top: 20px;
}

//
// Neos (Backend)
//
.company .neos-contentelement,
.company .neos-contentelement.neos-contentelement-active {
  outline: none !important;
}

.contact-form {
  .contact-form-column {
    @include make-xs-column(12);

    input, textarea {
      border: 0;
      border-radius: 0;
      box-shadow: none;
    }

    input[type=text],
    textarea {
      background-color: $color-lighter-blue;

      &:active,
      &:focus {
        background-color: transparentize($color-lighter-blue, 0.5);
      }
    }
  }

  .contact-form-company,
  .contact-form-name {
    @include make-sm-column(6);
  }

  .contact-form-submit {
    @include make-xs-column(2);

    input[type=submit] {
      background: url(asset_path('layout/arrows-blue.png')) no-repeat;
      background-position: 0% 52%;
      padding-left: 30px;
      text-align: left;
      color: $color-blue;
    }
  }

  .contact-form-confirmation,
  .contact-form-error {
    display: none;
    border: 0;
    border-radius: 0;
    margin: 50px 0;

    &.alert-success {
      background-color: $color-light-blue;
      color: inherit;
    }

    b {
      font-weight: 400;
    }
  }
}

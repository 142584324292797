.employee {
  margin-bottom: 20px;

  .employee-image {
    @include make-md-column(5);
    @include make-xs-column(6);

    img {
      width: 100%;
      height: auto;
    }
  }

  .employee-content {
    @include make-md-column(7);
    @include make-xs-column(6);

    h2, h3 {
      margin: 0;
      font-size: $font-size-base;
      font-weight: inherit;
      text-transform: none;
    }

    h2 {
      font-weight: 400;
    }

    h3 {
      color: inherit;
    }
  }

  .employee-contact {
    margin-top: 1em;

    dt, dl {
      float: left;
    }

    dt {
      width: 55px;
      font-weight: inherit;
    }
  }
}

.grid-content {
  @extend .canvas;

  h1 {
    margin-bottom: 15px;

    &:first-child {
      line-height: 1;
    }
  }

  h2 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-weight: 300;
    letter-spacing: 0;
  }

  .grid-content-col.col-12 {
    @media (min-width: $screen-md-min) {
      float: left;
      width: 100%;
    }
  }
}

//
// Bootstrap
//
// http://getbootstrap.com/customize/#less-variables
// https://github.com/twbs/bootstrap-sass/blob/v3.3.1/assets/stylesheets/bootstrap/_variables.scss
//

// Fix path to fonts
$icon-font-path: "/fonts/";

// Grid
$grid-gutter-width: 16px;
$container-large-desktop: (1180px + $grid-gutter-width);
$canvas-max-width: 1200px;

$grid-float-breakpoint: 1200px;

// Font families
$font-family-sans-serif: "Interface", "Helvetica Neue", "Helvetica", sans-serif;

// Font sizes
$line-height-base: 1.5;
$font-size-small: 10px;
$font-size-medium: 15px;
$font-size-base: 18px;
$font-size-large: 25px;
$font-size-h1: $font-size-large;
$font-size-h2: $font-size-large;
$font-size-h3: $font-size-base;
$font-size-h4: $font-size-base;
$font-size-h5: $font-size-base;

// Colors (blueish)
$color-blue: #0063b9;
$color-lighter-blue: #eff4fa;
$color-light-blue: #cfe2f3;

// Colors (grayscale)
$color-black: #232323;
$color-gray: #bdc1c4;
$color-white: #feffff;

// Colors (transparency)
$color-white-opaque: rgba($color-white, 0.85);

// Typography
$headings-line-height: 1.5;
$headings-color: $color-blue;

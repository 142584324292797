.box-content {
  position: relative;
  background-color: $color-gray;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 20px;
  padding: 15px 25px;

  &.lighter {
    background-color: $color-lighter-blue;
  }

  &.light {
    background-color: $color-light-blue;
  }

  &.tall {
    min-height: 200px;;

    @media(min-width: $screen-md-min) {
      height: 335px;
    }

    @media(min-width: $screen-lg-min) {
      height: 290px;
    }
  }

  &.taller {
    min-height: 330px;

    @media(min-width: $screen-lg-min) {
      height: 290px;
    }
  }

  &.tallest {
    min-height: 170px;

    @media(min-width: $screen-md-min) {
      height: 470px;
    }

    @media(min-width: $screen-lg-min) {
      height: 400px;
    }
  }

  &.special {
    min-height: 220px;
    background-size: 100px auto;
    background-position: center;

    @media(min-width: $screen-md-min) {
      background-size: 150px auto;
    }

    @media(min-width: $screen-lg-min) {
      background-size: 200px auto;
    }
  }

  &.low {
    min-height: 183px;

    @media(min-width: $screen-md-min) {
      height: 200px;
    }

    @media(min-width: $screen-lg-min) {
      height: 183px;
    }
  }

  &.thin-title {
    h2 {
      font-weight: 300;
      font-size: $font-size-base;

      @media(min-width: $screen-sm-min) {
        font-size: $font-size-h2;
      }
    }
  }

  &.white-title {
    position:relative;
    background:transparent center center no-repeat;
    background-size: cover;

    h2 {
      position: absolute;
      color: $color-white;
    }
  }

  &.white-link {
    a {
      background-image: url('../Assets/layout/arrows-white.png');
      color: $color-white;
    }
  }

  &.blue-content {
    background-image: none;
    color: $color-blue;

    p {
      padding-top: 15px;
      margin: 0;
      word-spacing: 3px;

      a {
        position: relative;
        bottom: 0;
        padding-left: 15px;
        background: none;
      }
    }
  }

  &.wide {
    @media(min-width: $screen-md-min) {
      &:first-of-type {
        margin-top: 186px;
      }
    }

    @media(min-width: $screen-lg-min) {
      &:first-of-type {
        margin-top: 111px;
      }
    }

    p{
      margin: 20px auto 45px;
    }
  }

  &.box-content.company {
    padding-left: 0;

    figure {
      @include make-xs-column(12);
      @include make-sm-column(12);

      margin-left: 12px   !important;
      padding-left: 0     !important;
      padding-right: 0    !important;
      margin-bottom: -1px !important;

      @media (min-width: $screen-md-min) {
        height: 145px;
      }

      img {
        width: 100%;
        height: 100%;
      }

      @media(min-width: $screen-sm-min) {
        max-width: 255px;
      }
    }

    > div {
      margin: 0;
    }

    > div:nth-child(2) {
      margin-left: -18px;
    }

    > div:nth-child(3) {
      margin-left: 15px;
    }

    > div:nth-child(2), > div:nth-child(3) {
      margin-left: 18px;
      @include make-sm-column-offset(6);
      @include make-md-column-offset(0);
    }

    @media(min-width: $screen-md-min) {
      figure {
        @include make-md-column(10);
        @include make-lg-column(11);
        margin: 4px 0 0 13px;
      }

      &:first-of-type {
        margin-top: 106px;
      }

      @media(min-width: $screen-lg-min) {
        &:first-of-type {
          margin-top: 66px;
        }
      }

    }

    p {
      line-height: 22px;
    }

    h3 + p {
      margin: 0 0 8px;
    }

    a {
      position: relative;
      bottom: 0;
      padding: 0;
      background: none;
      color: $color-black;
      font-size: 18px;
      font-weight: 300;
      letter-spacing: 0;
    }
  }

  &.bg-team {
    background-image: url('../Assets/pages/team.jpg');
  }

  &.bg-contact {
    background-image: url('../Assets/pages/contact.jpg');
  }

  &.bg-fea {
    background-image: url('../Assets/pages/friedrich-ebert-anlage.jpg');
  }

  &.bg-flexible-01 {
    background-image: url('../Assets/pages/bg-flexible-01.jpg');
  }

  &.bg-flexible-02 {
    background-image: url('../Assets/pages/bg-flexible-02.jpg');
  }

  &.bg-flexible-03 {
    background-image: url('../Assets/pages/bg-flexible-03.jpg');
  }

  &.bg-flexible-04 {
    background-image: url('../Assets/pages/bg-flexible-04.jpg');
  }

  h2 {
    margin: 0;
    letter-spacing: 1.5px;
    font-weight: 400;
  }

  h2 + h3 {
    margin-top: 15px;
  }

  ul {
    padding-top: 15px;
    padding-left: 20px;
    padding-bottom: 20px;
    list-style-type: square;
    font-size: 18px;

    li {
      padding-left: 5px;
    }
  }

  a {
    text-decoration: underline;
  }

  .box-content-more {
    position: absolute;
    bottom: 15px;
    background: url('../Assets/layout/arrows-blue.png') no-repeat;
    background-position: 0 5px;
    padding-left: 30px;
    letter-spacing: 0.8px;
    font-size: 18px;
    font-weight: 400;
    text-decoration: none;
  }
}
